.form,
.input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.form {
  margin-bottom: 10rem; // needed to bring SEND button up, otherwise covered behind footer on mobile
  margin-top: 1rem;
  width: 100%;

  .input {
    color: $color-primary-medium;
    width: 100%;
  }

  .input-group,
  textarea {
    width: 100%;
  }

  .input-group {
    input {
      @include form-input;
      @include respond(medium) {
        font-size: 2rem;
      }
    }
  }

  .label-group {
    .required {
      color: $color-secondary-medium;
    }
  }

  textarea {
    @include form-input;
    @include respond(medium) {
      font-size: 2rem;
    }
    margin-bottom: 1rem;
    resize: none;
  }
}
