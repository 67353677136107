body {
  color: $color-primary-dark;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: 1.6;

  @include respond(medium) {
    font-size: 2rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h4 {
  font-size: 1.8rem;

  @include respond(medium) {
    font-size: 2rem;
  }
}

li {
  list-style: none;
}

.active {
  color: $color-secondary-medium;
}

.description {
  color: $color-primary-medium;
  font-size: 1.8rem;
  margin: 0 auto 2rem;
  max-width: 80rem;
  text-align: center;

  @include respond(medium) {
    font-size: 2rem;
  }

  b {
    font-weight: 700;
  }
}

.details {
  ul > li {
    color: $color-primary-medium;

    b {
      font-weight: 700;
    }
  }
}

.hero {
  > h1 {
    text-align: center;

    > span {
      color: $color-secondary-dark;
    }
  }

  > h2 {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    text-align: center;

    @include respond(medium) {
      font-size: 2.4rem;
    }
  }
}

.link {
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $color-secondary-dark;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
  font-family: $font-family-secondary;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;

  @include respond(medium) {
    font-size: 3rem;
  }
}

.title {
  font-family: $font-family-secondary;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;

  @include respond(medium) {
    font-size: 5rem;
  }
}
