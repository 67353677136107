section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  margin: 0 auto;
  min-height: calc(100vh - 6rem);
}

.about,
.contact,
.portfolio,
.resume {
  padding-top: 10rem; /* Needed to avoid section title being too high up and covered by navbar on desktop or hamburger on mobile */

  @include respond(medium) {
    /* Stop content from getting wider than 900px */
    margin: 0 auto;
    max-width: 90rem;
  }
}

.email,
.social {
  font-weight: 700;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $color-secondary-dark;
  }
}

.main {
  background-color: $color-primary-light;
  padding: 1.2rem;
}

.portfolio {
  &-container {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 1rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @include respond(small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(medium) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);

      .overlay {
        opacity: 1;
      }
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;

    h3 {
      color: $color-secondary-light;
      font-size: 1.9rem;
      margin-bottom: 0.5rem;

      @include respond(medium) {
        font-size: 2.1rem;
      }

      @include respond(large) {
        font-size: 2.4rem;
      }
    }

    p {
      color: $color-secondary-light;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;

      @include respond(medium) {
        font-size: 1.7rem;
      }

      @include respond(large) {
        font-size: 1.9rem;
      }
    }

    .tech-stack {
      color: $color-secondary-light;
      font-size: 1.2rem;
      font-weight: 700;

      @include respond(medium) {
        font-size: 1.4rem;
      }

      @include respond(large) {
        font-size: 1.5rem;
      }
    }
  }
}
