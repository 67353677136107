.header {
  display: none;

  @include respond(small) {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 64px 8px rgba(40, 61, 59, 0.1);
    height: 6rem;
    width: 100%;
    padding: 1.2rem;
    z-index: 10;

    > nav > ul {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      > li > a {
        font-weight: 700;
        text-transform: uppercase;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: $color-secondary-dark;
        }
      }
    }
  }
}
