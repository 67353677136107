.hide {
  display: none;
}

.highlight {
  font-weight: 700;
}

.no-mb {
  margin-bottom: 0;
}
