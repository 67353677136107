.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px -8px 64px 8px rgba(40, 61, 59, 0.1);
  font-size: 1.8rem;
  height: 6rem;
  width: 100%;
  padding: 1.2rem;

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    max-width: 120rem;
    margin: 0 auto;

    @include respond(small) {
      gap: 12rem;
    }
  }
}

.social-icons {
  a {
    margin: 0 1rem;
    display: inline-block;
    &:hover .icon {
      transform: scale(1.2);
    }
  }

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    transition: transform 0.3s ease;
  }

  img {
    vertical-align: middle;
  }
}
