@mixin center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin form-input {
  border: none;
  border-radius: 6px;
  font-family: $font-family-primary;
  font-size: $font-size-base;
  padding: 1rem;
  width: 100%;
}

@mixin respond($breakpoint) {
  @if $breakpoint == small {
    @media only screen and (min-width: $breakpoint-small) {
      @content;
    }
  }

  @if $breakpoint == medium {
    @media only screen and (min-width: $breakpoint-medium) {
      @content;
    }
  }

  @if $breakpoint == large {
    @media only screen and (min-width: $breakpoint-large) {
      @content;
    }
  }
}

// Mixin for basic button styles
@mixin button-base {
  display: inline-block;
  background: transparent;
  color: $color-primary-dark;
  text-align: center;
  font-size: $button-font-size;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  padding: $button-padding;
  border: $button-border-width solid $color-primary-dark;
  border-radius: $button-border-radius;
  cursor: pointer;
  transition: $button-transition;
}

// Mixin for button hover effect
@mixin button-hover {
  color: $color-primary-light;
  background-color: $color-primary-dark;
  box-shadow: inset 0 -100px 0 0 $color-primary-dark;
}
