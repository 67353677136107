.mobile-nav__checkbox:checked ~ .mobile-nav__background {
  transform: scale(70);
}

.mobile-nav__checkbox:checked ~ .mobile-nav__nav {
  opacity: 1;
  width: 100%;
}

.mobile-nav__button:hover .mobile-nav__icon:before {
  top: -1rem;
}

.mobile-nav__button:hover .mobile-nav__icon:after {
  top: 1rem;
}

.mobile-nav__checkbox:checked + .mobile-nav__button .mobile-nav__icon {
  background-color: #0000;
}

.mobile-nav__checkbox:checked + .mobile-nav__button .mobile-nav__icon:before {
  top: 0;
  transform: rotate(135deg);
}

.mobile-nav__checkbox:checked + .mobile-nav__button .mobile-nav__icon:after {
  top: 0;
  transform: rotate(-135deg);
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  color: #283d3b;
  font-family: Nunito Sans, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
}

@media only screen and (width >= 56.25em) {
  body {
    font-size: 2rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h4 {
  font-size: 1.8rem;
}

@media only screen and (width >= 56.25em) {
  h4 {
    font-size: 2rem;
  }
}

li {
  list-style: none;
}

.active {
  color: #c44536;
}

.description {
  color: #197278;
  text-align: center;
  max-width: 80rem;
  margin: 0 auto 2rem;
  font-size: 1.8rem;
}

@media only screen and (width >= 56.25em) {
  .description {
    font-size: 2rem;
  }
}

.description b {
  font-weight: 700;
}

.details ul > li {
  color: #197278;
}

.details ul > li b {
  font-weight: 700;
}

.hero > h1 {
  text-align: center;
}

.hero > h1 > span {
  color: #772e25;
}

.hero > h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.2rem;
}

@media only screen and (width >= 56.25em) {
  .hero > h2 {
    font-size: 2.4rem;
  }
}

.link {
  transition: color .2s ease-in-out;
}

.link:hover {
  color: #772e25;
}

.list {
  flex-direction: column;
  margin-bottom: 1rem;
  display: flex;
}

.subtitle {
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
  font-family: Quicksand, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  display: flex;
}

@media only screen and (width >= 56.25em) {
  .subtitle {
    font-size: 3rem;
  }
}

.title {
  text-align: center;
  margin-bottom: 1rem;
  font-family: Quicksand, sans-serif;
  font-size: 4rem;
  font-weight: 700;
}

@media only screen and (width >= 56.25em) {
  .title {
    font-size: 5rem;
  }
}

.hide {
  display: none;
}

.highlight {
  font-weight: 700;
}

.no-mb {
  margin-bottom: 0;
}

.button {
  color: #283d3b;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 2px solid #283d3b;
  border-radius: .4rem;
  padding: 1.2rem 2.4rem;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .5s ease-out;
  display: inline-block;
}

@media only screen and (width >= 56.25em) {
  .button {
    font-size: 1.9rem;
  }
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    color: #edddd4;
    background-color: #283d3b;
    box-shadow: inset 0 -100px #283d3b;
  }
}

.button:active {
  transform: scale(.9);
}

@media (hover: none) and (pointer: coarse) {
  .button {
    color: #edddd4;
    box-shadow: none;
    background: #283d3b;
    font-weight: 700;
  }
}

.filter-buttons {
  justify-content: center;
  align-items: center;
  column-gap: .5rem;
  margin-bottom: 1rem;
  display: flex;
}

.filter-btn {
  color: #283d3b;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 1px solid #283d3b;
  border-radius: .4rem;
  padding: .5rem;
  font-size: 1.2rem;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.filter-btn.active {
  color: #edddd4;
  background-color: #283d3b;
  box-shadow: inset 0 -100px #283d3b;
}

@media (hover: hover) and (pointer: fine) {
  .filter-btn:hover {
    color: #edddd4;
    background-color: #283d3b;
    box-shadow: inset 0 -100px #283d3b;
  }
}

@media (hover: none) and (pointer: coarse) {
  .filter-btn.active {
    font-weight: 700;
  }
}

.button[type="submit"] {
  font-family: inherit;
}

.accordion {
  border: none;
  overflow: hidden;
}

.accordion-content {
  max-height: 0;
  transition: max-height .3s;
  overflow: hidden;
}

.accordion-content-inner {
  padding: 1.5rem;
}

.accordion-header {
  cursor: pointer;
  background-color: #fff6f0;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

.accordion-item {
  border-bottom: 2px solid #edddd4;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-item input[type="checkbox"] {
  display: none;
}

.accordion-item input[type="checkbox"]:checked + .accordion-header .chevron {
  transform: rotate(180deg);
}

.accordion-item input[type="checkbox"]:checked ~ .accordion-content {
  max-height: 200rem;
}

.chevron {
  width: 2rem;
  height: 2rem;
  transition: transform .3s;
  display: inline-block;
  position: relative;
}

.chevron:before {
  content: "";
  border-bottom: 3px solid #283d3b;
  border-right: 3px solid #283d3b;
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%)rotate(45deg);
}

.form, .input-group {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  display: flex;
}

.form {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 10rem;
}

.form .input {
  color: #197278;
  width: 100%;
}

.form .input-group, .form textarea {
  width: 100%;
}

.form .input-group input {
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 1rem;
  font-family: Nunito Sans, sans-serif;
  font-size: 1.6rem;
}

@media only screen and (width >= 56.25em) {
  .form .input-group input {
    font-size: 2rem;
  }
}

.form .label-group .required {
  color: #c44536;
}

.form textarea {
  resize: none;
  border: none;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  font-family: Nunito Sans, sans-serif;
  font-size: 1.6rem;
}

@media only screen and (width >= 56.25em) {
  .form textarea {
    font-size: 2rem;
  }
}

.footer {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 1.2rem;
  font-size: 1.8rem;
  display: flex;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -8px 64px 8px #283d3b1a;
}

.footer-content {
  justify-content: center;
  align-items: center;
  gap: 4rem;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
}

@media only screen and (width >= 37.5em) {
  .footer-content {
    gap: 12rem;
  }
}

.social-icons a {
  margin: 0 1rem;
  display: inline-block;
}

.social-icons a:hover .icon {
  transform: scale(1.2);
}

.social-icons .icon {
  width: 2.4rem;
  height: 2.4rem;
  transition: transform .3s;
}

.social-icons img {
  vertical-align: middle;
}

.header {
  display: none;
}

@media only screen and (width >= 37.5em) {
  .header {
    z-index: 10;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6rem;
    padding: 1.2rem;
    display: flex;
    position: fixed;
    box-shadow: 0 8px 64px 8px #283d3b1a;
  }

  .header > nav > ul {
    justify-content: space-between;
    gap: 2rem;
    display: flex;
  }

  .header > nav > ul > li > a {
    text-transform: uppercase;
    font-weight: 700;
    transition: color .2s ease-in-out;
  }

  .header > nav > ul > li > a:hover {
    color: #772e25;
  }
}

.list {
  padding-left: 2rem;
}

.list-item {
  color: #197278;
}

.list-item-title {
  color: initial;
  font-weight: 700;
}

.single-column {
  color: #197278;
  margin-left: 2rem;
}

@media only screen and (width >= 37.5em) {
  .single-column {
    margin-left: 0;
  }
}

.single-column-container {
  grid-template-columns: 1fr;
  display: grid;
}

@media only screen and (width >= 37.5em) {
  .single-column-container {
    grid-template-columns: 1fr 1fr;
    margin-left: 2rem;
  }
}

.two-columns {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.mobile-nav__checkbox {
  display: none;
}

.mobile-nav__button {
  z-index: 3000;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  box-shadow: 0 1rem 3rem #283d3b1a;
}

.mobile-nav__background {
  z-index: 2000;
  background: radial-gradient(#197278, #283d3b);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  transition: transform .4s cubic-bezier(.71, .01, .42, .83);
  position: fixed;
  top: 2.5rem;
  right: 2.5rem;
}

.mobile-nav__nav {
  opacity: 0;
  text-align: center;
  z-index: 2500;
  pointer-events: none;
  width: 0;
  height: 100vh;
  transition: all .4s cubic-bezier(.71, .01, .42, .83);
  position: fixed;
  top: 0;
  left: 0;
}

.mobile-nav__checkbox:checked ~ .mobile-nav__nav {
  pointer-events: auto;
}

.mobile-nav__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-nav__item {
  margin: 1rem;
}

.mobile-nav__link:link, .mobile-nav__link:visited {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 3rem;
}

.mobile-nav__icon {
  margin-top: 3.4rem;
  position: relative;
}

.mobile-nav__icon, .mobile-nav__icon:before, .mobile-nav__icon:after {
  background-color: #283d3b;
  width: 3rem;
  height: 4px;
  display: inline-block;
}

.mobile-nav__icon:before, .mobile-nav__icon:after {
  content: "";
  transition: all .2s;
  position: absolute;
  left: 0;
}

.mobile-nav__icon:before {
  top: -.8rem;
}

.mobile-nav__icon:after {
  top: .8rem;
}

@media only screen and (width >= 37.5em) {
  .mobile-nav {
    display: none;
  }
}

section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  min-height: calc(100vh - 6rem);
  margin: 0 auto;
  display: flex;
}

.about, .contact, .portfolio, .resume {
  padding-top: 10rem;
}

@media only screen and (width >= 56.25em) {
  .about, .contact, .portfolio, .resume {
    max-width: 90rem;
    margin: 0 auto;
  }
}

.email, .social {
  font-weight: 700;
  transition: color .2s ease-in-out;
}

.email:hover, .social:hover {
  color: #772e25;
}

.main {
  background-color: #edddd4;
  padding: 1.2rem;
}

.portfolio-container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.portfolio__grid {
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  display: grid;
}

@media only screen and (width >= 37.5em) {
  .portfolio__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (width >= 56.25em) {
  .portfolio__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.portfolio-item {
  border-radius: 8px;
  transition: transform .3s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px #0000001a;
}

.portfolio-item:hover {
  transform: translateY(-5px);
}

.portfolio-item:hover .overlay {
  opacity: 1;
}

.portfolio-item img {
  width: 100%;
  height: auto;
  display: block;
}

.portfolio .overlay {
  color: #fff;
  opacity: 0;
  background-color: #000000b3;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  transition: opacity .3s;
  display: flex;
  position: absolute;
  inset: 0;
}

.portfolio .overlay h3 {
  color: #fff6f0;
  margin-bottom: .5rem;
  font-size: 1.9rem;
}

@media only screen and (width >= 56.25em) {
  .portfolio .overlay h3 {
    font-size: 2.1rem;
  }
}

@media only screen and (width >= 75em) {
  .portfolio .overlay h3 {
    font-size: 2.4rem;
  }
}

.portfolio .overlay p {
  color: #fff6f0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
}

@media only screen and (width >= 56.25em) {
  .portfolio .overlay p {
    font-size: 1.7rem;
  }
}

@media only screen and (width >= 75em) {
  .portfolio .overlay p {
    font-size: 1.9rem;
  }
}

.portfolio .overlay .tech-stack {
  color: #fff6f0;
  font-size: 1.2rem;
  font-weight: 700;
}

@media only screen and (width >= 56.25em) {
  .portfolio .overlay .tech-stack {
    font-size: 1.4rem;
  }
}

@media only screen and (width >= 75em) {
  .portfolio .overlay .tech-stack {
    font-size: 1.5rem;
  }
}
/*# sourceMappingURL=index.cb98b766.css.map */
