.list {
  padding-left: 2rem;

  &-item {
    color: $color-primary-medium;

    &-title {
      color: initial;
      font-weight: 700;
    }
  }
}

.single-column {
  color: $color-primary-medium;
  margin-left: 2rem;

  @include respond(small) {
    margin-left: 0;
  }

  &-container {
    display: grid;
    grid-template-columns: 1fr;

    @include respond(small) {
      grid-template-columns: 1fr 1fr;
      margin-left: 2rem;
    }
  }
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
