.accordion {
  border: none;
  overflow: hidden;

  &-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;

    &-inner {
      padding: 1.5rem;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: $color-secondary-light;
    padding: 1.5rem;
    cursor: pointer;
  }

  &-item {
    border-bottom: 2px solid $color-primary-light;

    &:last-child {
      border-bottom: none;
    }

    & input[type='checkbox'] {
      display: none;

      &:checked {
        + .accordion-header .chevron {
          transform: rotate(180deg);
        }

        ~ .accordion-content {
          max-height: 200rem;
        }
      }
    }
  }
}

.chevron {
  display: inline-block;
  position: relative;
  width: 2rem;
  height: 2rem;
  transition: transform 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.2rem;
    height: 1.2rem;
    border-right: 3px solid $color-primary-dark;
    border-bottom: 3px solid $color-primary-dark;
    transform: translate(-50%, -75%) rotate(45deg);
  }
}
