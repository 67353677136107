/* Base button class */
.button {
  @include button-base;

  @include respond(medium) {
    font-size: 1.9rem;
  }

  /* Hover effect for non-touch screens */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include button-hover;
    }
  }

  /* Active effect for all devices */
  &:active {
    transform: scale(0.9);
  }

  /* Specific styles for touch screens */
  @media (hover: none) and (pointer: coarse) {
    background: $color-primary-dark;
    color: $color-primary-light;
    font-weight: 700;
    box-shadow: none;
  }
}

/* Filter buttons in Portfolio section */
.filter-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  margin-bottom: 1rem;
}

.filter-btn {
  @include button-base;
  border-width: 1px;
  font-size: $button-font-size * 0.8;
  padding: 0.5rem;
  transition: all 0.3s ease;

  &.active {
    @include button-hover;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include button-hover;
    }
  }

  // No background change on touch devices
  @media (hover: none) and (pointer: coarse) {
    &.active {
      font-weight: 700;
    }
  }
}

// Submit button
.button[type='submit'] {
  font-family: inherit;
}
