.mobile-nav {
  // Open mobile menu
  &__checkbox:checked ~ &__background {
    transform: scale(70);
  }
  &__checkbox:checked ~ &__nav {
    width: 100%;
    opacity: 1;
  }

  // Hamburger animation
  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}
